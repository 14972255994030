import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

import { relatedPosts as wrapper } from "./related-posts.module.scss"

const RelatedPosts = ({ posts, currentSlug }) => {
  let relatedPosts

  if (posts && Array.isArray(posts.nodes)) {
    relatedPosts = posts.nodes
      .filter((post) => {
        return post.childMdx.frontmatter.slug !== currentSlug
      })
      .map((post) => ({
        title: post.childMdx.frontmatter.title,
        slug: post.childMdx.frontmatter.slug,
      }))
  } else {
    relatedPosts = []
  }

  return (
    <div className={wrapper}>
      <h3>You May Also Like</h3>
      <ul>
        {relatedPosts.map(({ slug, title }) => {
          return (
            <li key={slug}>
              <Link to={`/articles/${slug}`}>{title}</Link>
            </li>
          )
        })}
      </ul>
    </div>
  )
}

RelatedPosts.propTypes = {
  posts: PropTypes.shape({
    nodes: PropTypes.arrayOf(
      PropTypes.shape({
        childMdx: PropTypes.shape({
          frontmatter: PropTypes.shape({
            title: PropTypes.string.isRequired,
            slug: PropTypes.string.isRequired,
          }),
        }),
      })
    ).isRequired,
  }).isRequired,
  currentSlug: PropTypes.string.isRequired,
}

export default RelatedPosts
