import React from "react"
import { graphql } from "gatsby"
import { MDXProvider } from "@mdx-js/react"

import LayoutMain from "../components/layout-main/layout-main"
import SeoHeaders from "../components/seo-headers/seo-headers"
import HeaderMain from "../components/header-main/header-main"
import ArticlePost from "../components/article-post/article-post"
import SidebarMain from "../components/sidebar-main/sidebar-main"
import VideoEmbed from "../components/video-embed/video-embed"
import RelatedPosts from "../components/related-posts/related-posts"

export default function Template({ data }) {
  const { article, related } = data
  const { frontmatter, body } = article
  const shortcodes = { VideoEmbed }

  return (
    <LayoutMain className="page-article">
      <SeoHeaders
        isBlogPost={true}
        frontmatter={frontmatter}
        title={frontmatter.title}
      />
      <HeaderMain />
      <MDXProvider components={shortcodes}>
        <section>
          <div className="content-section">
            <div className="content-section-inner">
              <div className="content-holder">
                <ArticlePost html={body} frontmatter={frontmatter} />
                {related && related.nodes.length > 1 && (
                  <RelatedPosts
                    posts={related}
                    currentSlug={frontmatter.slug}
                  />
                )}
                {/* TODO: show a CTA */}
                {/* TODO: show an about the author block */}
              </div>
              <div className="content-sidebar">
                <SidebarMain />
              </div>
            </div>
          </div>
        </section>
      </MDXProvider>
    </LayoutMain>
  )
}

export const ArticleBySlug = graphql`
  query ($slug: String!, $categorySlug: String!) {
    article: mdx(frontmatter: { slug: { eq: $slug } }) {
      frontmatter {
        title
        metaTitle
        slug
        date
        categorySlug
        category
        author
        keywords
        bannerCaption
        description
        banner {
          id
          childImageSharp {
            fluid(maxWidth: 1200) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      id
      body
    }
    related: allFile(
      filter: {
        childMdx: { frontmatter: { categorySlug: { eq: $categorySlug } } }
        sourceInstanceName: { eq: "articles" }
      }
    ) {
      nodes {
        id
        childMdx {
          excerpt
          frontmatter {
            title
            slug
            banner {
              id
              childImageSharp {
                fluid(maxWidth: 500) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  }
`
