import React from "react"

import KeywordList from "../keyword-list/keyword-list"
import PostImage from "../featured-image/featured-image"
import PostMeta from "../post-meta/post-meta"
import { MDXRenderer } from "gatsby-plugin-mdx"

import { blogPost } from "./article-post.module.scss"

const ArticlePost = ({ html, frontmatter }) => {
  const {
    title,
    date,
    category,
    categorySlug,
    author,
    banner,
    bannerCaption,
    keywords,
  } = frontmatter

  const featuredImage =
    banner && banner.childImageSharp ? banner.childImageSharp.fluid : null

  return (
    <div className={blogPost}>
      <h1>{title}</h1>
      <PostMeta
        date={date}
        category={category}
        categorySlug={categorySlug}
        author={author}
      />
      {featuredImage && (
        <PostImage image={featuredImage} caption={bannerCaption} />
      )}
      <div>
        <MDXRenderer>{html}</MDXRenderer>
      </div>
      {keywords && <KeywordList keywords={keywords} />}
    </div>
  )
}

export default ArticlePost
