import React from "react"

import './keyword-list.scss'

const KeywordList = ({ keywords }) => (
  <div className="post-tags">
    <h3>Tags</h3>
    {
      keywords.map(keyword => (
        <div className="post-tag" key={keyword}>{keyword}</div>
      ))
    }
  </div>
)

export default KeywordList;
